/* eslint-disable react/prop-types */
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

export const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const DashboardRoute = ({ children }) => {
  const { isVerified } = useSelector((state) => state.auth);

  if (!isVerified) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
