import { IoEyeOff, IoEye } from "react-icons/io5";

const CustomInput = ({
  type,
  name,
  placeholder,
  onChange,
  onBlur,
  showPassword,
  toggleShowPassword,
  error,
  Icon,
  Button,
}) => {
  return (
    <div
      className={`${
        error && "border-red-600"
      } flex items-center px-4 py-2 rounded-xl shadow-sm border border-[#4a4947]`}
    >
      {Icon && <Icon className="text-gray-500" />}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className="bg-transparent flex-1 outline-none px-3 text-white w-5"
      />
      {Button && (
        <button type="button" onClick={toggleShowPassword}>
          {showPassword ? (
            <IoEye className="text-gray-500" />
          ) : (
            <IoEyeOff className="text-gray-500" />
          )}
        </button>
      )}
    </div>
  );
};

export default CustomInput;
