/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */

import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { axiosInstance } from "./api/axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { lazy, Suspense } from "react";

// Import authentication-related components
import { PrivateRoute } from "./components/common/PrivateRoute";
import { DashboardRoute } from "./components/common/PrivateRoute";
import { SignUpComponent } from "./pages/Auth/SignUp";
import ErrorPage from "./components/common/Error";

// Import Redux actions
import {
  setAuth,
  setName,
  setIsVerified,
  setUID,
} from "./redux/reducers/authReducer";
import { token_verification } from "./api/auth";
import LoadingSkeleton from "./components/common/LoadingSkeleton";

// Lazy-loaded components for code splitting and better performance
const Login = lazy(() =>
  import("./pages/Auth/Login").then((module) => ({ default: module.Login }))
);
const Dashboard = lazy(() =>
  import("./pages/Dashboard/Dashboard").then((module) => ({
    default: module.Dashboard,
  }))
);
const DashboardPage = lazy(() =>
  import("./pages/DashboardPage/DashboardPage").then((module) => ({
    default: module.DashboardPage,
  }))
);

const Scan = lazy(async () => {
  const module = await import("./pages/Scan/ScanPage");
  return { default: module.ScanComponent }; // Explicitly setting default export
});
const ScanList = lazy(() => import("./pages/ScanHistory/ScanList"));
const ProfileComponent = lazy(() =>
  import("./pages/Dashboard/Navbar/Profile/Profile").then((module) => ({
    default: module.ProfileComponent,
  }))
);

const HistoryReportData = lazy(() => import("./pages/Scan/ScanReportPage"));
const CVEPage = lazy(async () => {
  const module = await import("./pages/CVE/CVEPage");
  return { default: module.default || module.CVEPage }; // Handle both cases
});
const PasswordReset = lazy(() =>
  import("./pages/Auth/PasswordReset/PasswordReset")
);
const PasswordResetRequest = lazy(() =>
  import("./pages/Auth/PasswordReset/PasswordResetRequest")
);
const EmailVerification = lazy(() =>
  import("./pages/Auth/EmailVerification/EmailVerification")
);

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Define an async function inside useEffect to avoid directly passing an async function
    async function fetchData() {
      try {
        const response = await axiosInstance.get(token_verification);

        if (response.status === 200) {
          const { email, is_verified, id, full_name } =
            response.data.data || {};

          // Update Redux store with authentication data
          dispatch(setAuth({ loggedIn: true, email: email || "" }));
          dispatch(setIsVerified({ isVerified: is_verified }));
          dispatch(setUID({ uid: id }));
          dispatch(setName({ full_name: full_name }));

          // Navigate to the same page (prevents automatic page switching by keeping the user on their intended page)
          navigate(location.pathname, {
            state: { from: location },
            replace: true,
          });
        }
      } catch (err) {
        console.error("Error fetching authentication data:", err);

        // If verification fails, update Redux store to reflect logged-out status
        dispatch(setAuth({ loggedIn: false, email: "" }));
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <LoadingSkeleton />
        </div>
      }
    >
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<SignUpComponent />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route
          path="/password-reset-request"
          element={<PasswordResetRequest />}
        />
        <Route path="/password-reset/:uid/:token" element={<PasswordReset />} />

        {/* Private Routes - Protected by Authentication */}
        <Route
          path="/verification"
          element={
            <PrivateRoute>
              <EmailVerification />
            </PrivateRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardRoute>
                <Dashboard />
              </DashboardRoute>
            </PrivateRoute>
          }
        >
          {/* Nested Routes inside Dashboard */}
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="scan" element={<Scan />} />
          <Route path="profile" element={<ProfileComponent />} />
          <Route path="scan-list" element={<ScanList />} />
          <Route
            path="/historyreportdata/:scanId"
            element={<HistoryReportData />}
          />
          <Route path="/CVE-details" element={<CVEPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
