import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

// When using this button component
// If you need to use tailwind CSS classes,add them with !important to override the MUI customStyles
// Example: <CustomButton className="!rounded-full">Get Started</CustomButton>

const getCustomStyles = (darkMode) => ({
  primaryBtn: {
    backgroundColor: darkMode ? "white" : "#14212D",
    color: darkMode ? "black" : "white",
    "&:hover": {
      backgroundColor: darkMode ? "#FFFFFFCC" : "#14212DCC",
    },
  },
  outlinedPrimaryBtn: {
    border: `1px solid ${darkMode ? "white" : "#14212D"}`,
    color: darkMode ? "white" : "black",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      border: `1px solid ${darkMode ? "#FFFFFFCC" : "#14212DCC"}`,
    },
  },
});

const CustomButton = React.forwardRef(
  ({ variant, size, color, styleType, ...props }, ref) => {
    const { darkMode } = useSelector((state) => state.darkMode);
    const customStyles = React.useMemo(
      () => getCustomStyles(darkMode),
      [darkMode]
    );

    // if (!customStyles[styleType]) {
    //   console.warn(
    //     `Invalid styleType: ${styleType}. Falling back to default style.`
    //   );
    // }

    return (
      <Button
        variant={variant}
        size={size}
        color={color}
        ref={ref}
        className={props.className}
        sx={customStyles[styleType] || customStyles.default}
        {...props}
      />
    );
  }
);

CustomButton.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf(["primary", "success", "error"]),
  styleType: PropTypes.oneOf(["primaryBtn", "outlinedPrimaryBtn", "default"]),
  className: PropTypes.string,
};

CustomButton.defaultProps = {
  variant: "contained",
  size: "medium",
  color: "primary",
  styleType: "default",
};

CustomButton.displayName = "CustomButton";

export { CustomButton };
