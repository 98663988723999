/* eslint-disable react/react-in-jsx-scope */
import { useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { FaUser, FaUserAlt, FaEnvelope, FaLock } from "react-icons/fa";

import {
  setLoading,
  setFeedback,
  setError,
  setMessage,
} from "../../redux/reducers/registrationReducer";
// import {
//   CustomButton,
//   axiosInstance,
//   registration,
//   signupValidationSchema,
//   AuthLayout,
//   CustomInput,
// } from "./index";
import { CustomButton } from "../../components/ui/button";
import { axiosInstance } from "../../../src/api/axios";
import { registration } from "../../api/auth";
import { signupValidationSchema } from "../../helpers/authValidationSchema";
import AuthLayout from "./components/AuthLayout";
import CustomInput from "../../components/shared/CustomInput";


export const SignUpComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.registration.loading);
  const error = useSelector((state) => state.registration.error);
  const message = useSelector((state) => state.registration.message);
  const abortControllerRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleRegistration = async (values, { setSubmitting, resetForm }) => {
    abortControllerRef.current = new AbortController();
    dispatch(setLoading({ loading: true }));
    dispatch(setError({ error: false }));
    dispatch(setMessage({ message: "" }));

    try {
      const user_data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        password_confirm: values.confirmPassword,
      };
      const response = await axiosInstance.post(registration, user_data, {
        headers: { "Content-type": "application/json" },
      });

      if (response.status === 201) {
        dispatch(setLoading({ loading: false }));
        resetForm();
        navigate("/login", { state: { from: location }, replace: true });
      } else {
        dispatch(setLoading({ loading: false }));
        dispatch(setFeedback({ feedback: true }));
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        if (err.response.data.email) {
          dispatch(setError({ error: true }));
          dispatch(
            setMessage({ message: "Error while signing up. Please try again." })
          );
        } else {
          dispatch(setError({ error: true }));
          dispatch(
            setMessage({ message: "Error while signing up. Please try again." })
          );
        }
      } else {
        dispatch(setError({ error: true }));
        dispatch(
          setMessage({ message: "Error while signing up. Please try again." })
        );
      }
      dispatch(setLoading({ loading: false }));
      dispatch(setFeedback({ feedback: true }));
    } finally {
      dispatch(setLoading({ loading: false }));
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupValidationSchema,
    onSubmit: handleRegistration,
  });

  return (
    <AuthLayout>
      {/* Right Side */}
      <Box className="flex flex-col gap-2 text-white">
        <div className="text-2xl text-center font-semibold ">Get Secured</div>
        <div className="mb-4 text-muted-foreground text-sm text-center">
          Create an account to start vulnerability testing
        </div>

        {error && <div className="text-sm text-red-500 mb-2">{message}</div>}
        <div className="flex flex-col gap-3 mb-3">
          {inputFields.map((field, index) => (
            <div key={index}>
              <CustomInput
                type={
                  field.type === "password"
                    ? showPassword
                      ? "text"
                      : "password"
                    : field.type
                }
                name={field.name}
                placeholder={field.placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showPassword={showPassword}
                toggleShowPassword={() =>
                  field.type === "password" && setShowPassword(!showPassword)
                }
                error={formik.errors[field.name]}
                Icon={field.icon}
                Button={field.type === "password"}
              />
              {formik.errors[field.name] && (
                <div className="text-sm text-red-500 mt-3">
                  {formik.errors[field.name]}
                </div>
              )}
            </div>
          ))}
        </div>

        <CustomButton
          styleType="primaryBtn"
          type="submit"
          data-testid="create-account" //inserted id for testing purposes
          onClick={formik.handleSubmit}
          className="!font-bold !bg-white !text-black"
          disabled={isLoading}
        >
          {isLoading ? "Creating Account..." : "Create Account"}
        </CustomButton>

        <div className="px-8 text-center text-sm text-muted-foreground mt-4">
          Already have an account?{" "}
          <Link to="/login">
            <span className="text-blue-500 cursor-pointer">Login</span>
          </Link>
        </div>
      </Box>
    </AuthLayout>
  );
};

const inputFields = [
  {
    name: "firstName",
    placeholder: "First Name",
    type: "text",
    icon: FaUser,
  },
  {
    name: "lastName",
    placeholder: "Last Name",
    type: "text",
    icon: FaUserAlt,
  },
  {
    name: "email",
    placeholder: "Email",
    type: "email",
    icon: FaEnvelope,
  },
  {
    name: "password",
    placeholder: "Password",
    type: "password",
    icon: FaLock,
  },
  {
    name: "confirmPassword",
    placeholder: "Confirm Password",
    type: "password",
    icon: FaLock,
  },
];
