import { createSlice } from "@reduxjs/toolkit";

// Load the initial state from localStorage or fallback to false
const initialState = {
  darkMode: JSON.parse(localStorage.getItem("darkMode")) || false,
};

const darkModeReducer = createSlice({
  name: "darkMode",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
      // Persist the darkMode state to localStorage
      localStorage.setItem("darkMode", JSON.stringify(state.darkMode));
    },
  },
});

export const { toggleDarkMode } = darkModeReducer.actions;
export default darkModeReducer.reducer;
