//created this file to combine all the reducers into a single reducer
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import routeReducer from "./reducers/routeReducer";
import navItemReducer from "./reducers/navItemReducer";
import registrationReducer from "./reducers/registrationReducer";
import loginReducer from "./reducers/loginReducer";
import emailVerificationReducer from "./reducers/emailVerificationReducer";
import profileReducer from "./reducers/profileReducer";
import passwordResetReducer from "./reducers/passwordResetReducer";
import scanDataReducer from "./reducers/scanDataReducer";
import scanReducer from "./reducers/scanReducer";
import scanMetricsReducer from "./reducers/scanMetricsReducer";
import severityCountsReducer from "./reducers/severityCountsReducer";
import darkModeReducer from "./reducers/darkModeReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  route: routeReducer,
  navitem: navItemReducer,
  registration: registrationReducer,
  login: loginReducer,
  email_verification: emailVerificationReducer,
  profile: profileReducer,
  password_reset: passwordResetReducer,
  scanData: scanDataReducer,
  scan: scanReducer,
  scanMetrics: scanMetricsReducer,
  severityCounts: severityCountsReducer,
  darkMode: darkModeReducer,
});

export default rootReducer;
