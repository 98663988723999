import Loading from "../../assets/json/loadingMain.json";
import Lottie from "lottie-react";

const LoadingSkeleton = () => {
  return (
    <div className="flex justify-center items-center h-96">
      <Lottie animationData={Loading} loop={true} />
    </div>
  );
};

export default LoadingSkeleton;
