// Centralized ToastProvider for managing toast notifications using 'react-toastify'.
import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Centralized toast configuration to avoid repetition
const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const ToastProvider = ({ children }) => {
  return (
    <>
      {children} {/* Renders wrapped content ensuring reusability */}
      <ToastContainer {...TOAST_CONFIG} /> {/* Applies centralized config */}
    </>
  );
};

// Prop validation to ensure correct usage and better maintainability
ToastProvider.propTypes = {
  children: PropTypes.node, 
};

// Useful for debugging purposes
ToastProvider.displayName = "ToastProvider";

export default ToastProvider;