/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import SecureNEX from "../../../assets/images/secureNEX_min.png";
import bgBackground from "../../../assets/images/bg-black.jpg";

const AuthLayout = ({ children, maxWidth }) => {
  return (
    <div
      className="flex h-screen bg-cover bg-center items-center justify-center"
      style={{ backgroundImage: `url(${bgBackground})` }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 mx-3 md:mx-5 gap-1 md:gap-10 lg:gap-32">
        {/* Left Side */}
        <div className="flex justify-center flex-col gap-4 items-center text-white xxs:mt-10 md:mt-0">
          <div className="mb-5 flex items-center flex-col text-center">
            <p className="text-4xl">
              Detect and <span className="font-bold">fix</span>
            </p>
            <p className="text-4xl">vulnerabilities faster</p>
            <p className="text-lg mt-2 text-gray-400">
              Ensure Security with Advanced Automated
            </p>
            <p className="text-lg text-gray-400">VAPT Assessments</p>
          </div>

          <img
            src={SecureNEX}
            className="w-[140px] h-28 relative left-2 shadow-xl hidden md:block"
            alt="logo"
          />

          <div className="text-2xl font-semibold text-center hidden md:block">
            SecureNEX
          </div>
        </div>
        {/* Right Side */}
        <div
          className={`flex items-center justify-center px-5 py-10 xs:px-10 bg-white/10 backdrop-blur-[3px] shadow-xl rounded-lg border border-black/80 ${maxWidth}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
