/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";

import "./assets/css/style.scss";
import store from "./redux/store";
import App from "./App";
import { TooltipProvider } from "./components/ui/tooltip";
import ToastProvider from "./components/ui/ToastProvider";

const Root = () => {
  const { darkMode } = useSelector((state) => state.darkMode);

  // Add or remove the `dark` class on the HTML element
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
    breakpoints: {
      values: {
        xxs: 320,
        xs: 480, // 320 - 480 Mobile Phones (Portrait)
        sm: 640,
        md: 768, // 768-1024 Tablets (Portrait)
        lg: 1024, //1024 - 1920 Desktops
        xl: 1280,
        xxl: 1536,
      },
    },
  });

  return (
    <ToastProvider>
      <BrowserRouter>
        <TooltipProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </TooltipProvider>
      </BrowserRouter>
    </ToastProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Root />
  </Provider>
);
